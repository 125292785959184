/* <TO_RENAME> */
.donationContext {
    background-color: #f2f2f2;
    padding: 14px 39px 14px 39px;
    margin-left: 18px;
    text-align: center;
    font-size: 12px;
    color: #222;
}

.donationContext--bold {
    font-weight: 500;
}

@media screen and (min-width: 1221px) {
    .donationContext {
        font-size: 0.875rem;
        padding: 18px 39px 18px 39px;
    }
}
/* </TO_RENAME> */


@media screen and (prefers-color-scheme: dark) {
    .btnContribution {
        --button-background-color: #ffe502;
        --button-border-color: #222;
        --button-text-color: #222;
        --button-hover-background-color: #fde831;
        --button-hover-border-color: #323232;
        --button-hover-text-color: #323232;
        --button-active-background-color: #fcec63;
        --button-active-border-color: #646464;
        --button-active-text-color: #646464;
    }
}

@media screen and (prefers-color-scheme: light) {
    .btnContribution {
        --button-background-color: #ffe502;
        --button-border-color: #222;
        --button-text-color: #222;
        --button-hover-background-color: #fde831;
        --button-hover-border-color: #323232;
        --button-hover-text-color: #323232;
        --button-active-background-color: #fcec63;
        --button-active-border-color: #646464;
        --button-active-text-color: #646464;
    }
}

.btnContribution {
    display: inline-block;
    text-align: center;
    font-family: rubrik, Arial, sans-serif;
    font-weight: 500;
    color: var(--button-text-color);
    border: 1px solid var(--button-border-color);
    background-color: var(--button-background-color);
    text-decoration: none;
    height: 40px;
    min-width: 165px;
    padding-right: 15px;
    padding-left: 15px;
    line-height: 40px;
    font-size: 0.6875em;
    border-radius: 24px;
    margin-top: 6px;
}

.btnContribution:hover {
    border: solid 1px var(--button-hover-border-color);
    color: var(--button-hover-text-color);
    background-color: var(--button-hover-background-color);
}

.btnContribution:active {
    color: var(--button-active-text-color);
    border: solid 1px var(--button-active-border-color);
    background-color: var(--button-active-background-color);
}

.btnContribution--bold {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 2px;
    line-height: initial;
    width: initial;
    min-width: 190px;
    display: inline-flex;
    align-items: center;
}

.btnContribution__heartImg {
    content: url("./heart.inline.svg");
    max-width: 22px;
}

.btnContribution__heartContainer {
    border-radius: 24px;
    width: 34px;
    height: 34px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnContribution__content {
    margin: 0 9px 0 9px;
    min-width: 100px;
}

@media screen and (min-width: 1024px) {
    .btnContribution {
        font-size: 0.875em;
        min-width: 195px;
    }
}

@media screen and (min-width: 1221px) {
    .btnContribution--bold {
        font-size: 1rem;
    }
}
